<template>
	<div
		class="full-height flex-column"
	>
		<div
			class="bg-white  full-height overflow-y-auto"
		>

			<div class="full-width ">

				<Search
					:search="item_search"
					:option="search_option"

					@change="getData"
					@click="getData"
					@toExcel="toExcel"
					class="mt-10"
				>
					<select
						slot="add"
						v-model="item_search.virtual_uid"
						class="mr-10 input-box-inline size-px-12"
						@change="getSearch(1)"
					>
						<option value="">지갑 구분</option>
						<option
							v-for="(virtual, v_index) in items_virtual"
							:key="'virtual_' + v_index"
							:value="virtual.uid"
						>{{ virtual.virtual_name }}</option>
					</select>
				</Search>

				<div
					v-if="user.agency_type != 'agency'"
					class="pa-10 text-right"
				>
					<button
						class="btn btn-inline btn-success mr-10"
						@click="onAgency"
					>영업점 조회</button>
					<span
						v-if="item_agency"
						class="ml-10 input-box"
					>{{ item_agency.agency_name }}</span>


					<select
						v-model="item.virtual_uid"
						class="mr-10 input-box-inline size-px-12"
						@change="getSearch(1)"
					>
						<option value="">지갑 구분</option>
						<option
							v-for="(virtual, v_index) in items_virtual"
							:key="'virtual_' + v_index"
							:value="virtual.uid"
						>{{ virtual.virtual_name }}</option>
					</select>

					예치금 입금: <input v-model="item.amount" type="number" :rules="$rules.max(item, 'amount', 9)" class="input-box-inline mr-10" placeholder="입금 금액" />
					입금 내용: <input v-model="item.memo" class="input-box-inline mr-10" placeholder="내용 입력" />
					<button
						class="btn-inline btn-primary"
						@click="postDeposit"
					>등록</button>
				</div>

				<div
					v-if="items.length > 0"
				>
					<table
						class="mt-10 table table-even"
					>
						<colgroup>
						</colgroup>
						<thead>
						<tr>
							<th>관리 번호</th>
							<th>아이디</th>
							<th>입금자 아이디</th>

							<th v-if="false">전송 구분</th>
							<th v-if="false">구분</th>
							<th>지갑 구분</th>
							<th>승인 상태</th>

							<th>금액</th>
							<th>등록일</th>
							<th>상세정보</th>
						</tr>
						</thead>
						<tbody
							v-if="items.length > 0"
						>
						<tr
							v-for="(item, index) in list_history"
							:key="item.uid + index"
							:title="item.memo"
						>
							<td>{{ item.uid }}</td>
							<td>{{ item.account_id }}</td>

							<td>{{ item.bank_depositor }}</td>
							<td>{{ item.virtual_name }}</td>
							<td :class="'color-' + item.status_color">{{ item.status_name }}</td>
							<td :class="'color-' + item.history_type_color">{{ item.amount | makeComma }}원</td>

							<td>{{ item.wDate }}</td>
							<td>
								<button
									class="btn-primary pa-5-10"
									@click="onDetail(item)"
								>상세보기</button>
								<button
									v-if="item.is_deposit"
									class="ml-10 btn-success pa-5-10"
									@click="confirmDeposit(item)"
								>승인</button>
								<button
									v-if="item.is_delete"
									class="ml-10 btn-danger pa-5-10"
									@click="deleteDeposit(item)"
								>삭제</button>
								<button
									v-if="item.is_cancel"
									class="ml-10 btn-danger pa-5-10"
									@click="cancelDeposit(item)"
								>취소</button>
							</td>
						</tr>
						</tbody>
					</table>

					<Pagination
						:program="program"
						:align="'center'"
						:options="item_search"

						class="mt-auto"
						@click="getSearch"
					></Pagination>
				</div>
				<Empty
					v-else
				></Empty>
			</div>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@close="is_excel = false"
		></Excel>

		<PopupLayer
			v-if="is_on_detail"

			@cancel="is_on_detail = false"
		>
			<template
				v-slot:body
			>
				<div class="bg-white width-720 ma-auto flex-column justify-space-between " style="max-height: 480px">
					<div class="pa-10-20 justify-space-between under-line">
						<h4>예치금 입금 상세 내역</h4>
						<v-icon
							@click="is_on_detail = false"
						>mdi-close</v-icon>
					</div>

					<div
						class="pa-20 overflow-y-auto"

					>
						<div>
							<table class="table table-even">
								<thead>
								<tr>
									<th>아이디</th>
									<th>입금자 아이디</th>
									<th>지갑 구분</th>
									<th>승인상태</th>
									<th>금액</th>
									<th>등록일</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>{{ item_detail.account_id }}</td>
									<td>{{ item_detail.bank_depositor }}</td>
									<td>{{ item_detail.virtual_name }}</td>
									<td>{{ item_detail.status_name }}</td>
									<td>{{ item_detail.amount | makeComma }}원</td>
									<td>{{ item_detail.wDate }}</td>
								</tr>
								</tbody>
							</table>
						</div>
						<div class="mt-20" :inner-html.prop="item_detail.memo | nl2br"></div>
					</div>
				</div>
			</template>

		</PopupLayer>

		<Modal
			:is_modal="is_bussiness"
			:option="modal_option"
			:width="'560px'"
			:height="'480px'"

			@cancel="is_bussiness = false"
			@close="is_bussiness = false"
			@click="setAgency"
		>
			<div
				slot="modal-content"
			>
				<div>
					<input
						v-model="item_search_agency.search_value"
						class="input-box-inline mr-10"
						placeholder="영업점 명을 입력하세요"
					/>
					<button
						@click="getAgency(1)"
						class="box pa-5-10 bg-base"
					>조회</button>
				</div>
				<table
					class="table mt-10"
				>
					<thead>
					<tr>
						<th>구분</th>
						<th>영업점 명</th>
						<th>선택</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="(agency, index) in items_agency"
						:key="'agency_' + index"
						:class="{on: agency.uid == item_agency_select.uid}"
					>
						<td>{{ agency.agency_type_name}}</td>
						<td class="text-left">{{ agency.agency_name}}</td>
						<td><button @click="selectAgency(agency)" class="btn-blue pa-5-10">선택</button></td>
					</tr>
					</tbody>
				</table>

				<Pagination
					v-if="items_agency.length > 0"
					:program="program"
					:align="'center'"
					:options="item_search_agency"

					class="mt-auto"
					@click="getAgency"
				></Pagination>

				<Empty
					v-else
				></Empty>
			</div>
		</Modal>
	</div>
</template>

<script>

import Search from "../Layout/Search";
import Pagination from "../../components/Pagination";
import Empty from "../Layout/Empty";
import Excel from "@/components/Excel";
import PopupLayer from "@/view/Layout/PopupLayer";
import Modal from "../../components/Modal";

export default {
	name: 'WalletDeposit'
	, props: ['user', 'codes', 'virtual_uid', 'auth', 'date']
	, components: {Modal, PopupLayer, Excel, Empty, Search, Pagination}
	, data: function(){
		return {
			program: {
				name: '예치금 입금 내역'
				,top: true
				,title: true
			}
			, items: []
			, item_search: {
				virtual_uid: this.$route.params.idx
				, virtual_status: ''
				, virtual_able: ''
				, list_cnt: 10
				, search_type: 'member_id'
				, is_use: ''
				, total_count: 0
				, page: 1
				, tCnt: 0
				, send_type: ''
				, history_type: ''
				, sDate: this.$date.getLastDate(7, '-')
				, eDate: this.$date.getToday('-')
				, upper_id: ''
				, admin_code: ''
				, status: ''
			}
			,search_option:{

				is_cnt: true
				, is_excel: true
				, cnt: 0
				, tCnt: 0
				, search_type: [
					{ name: '아이디', column: 'member_id'}
					, { name: '입금자 아이디', column: 'bank_depositor'}
				]
				, sDate: true
				, eDate: true
			}
			, agency_list: []
			, agency_list2: []
			, is_excel: false
			, excel_data: {
				name: '예치금 입금 내역'
				,header: [
					{ key: 0, name: '아이디', column: 'account_id'}
					,{ key: 0, name: '입금자 아이디', column: 'bank_depositor'}
					,{ key: 0, name: '지갑 구분', column: 'virtual_name'}
					,{ key: 0, name: '승인상태', column: 'status_name'}
					,{ key: 0, name: '금액', column: 'amount'}
					,{ key: 0, name: '메모', column: 'memo'}
					,{ key: 0, name: '등록일', column: 'wDate'}
				]
				,content: null
			}
			, is_on_confirm: false
			, item_confirm:{

			}
			, memo: ''
			, is_on_cancel: false
			, is_on_memo: false
			, item: {
				amount: ''
				, memo: ''
				, shop_uid: ''
				, virtual_uid: ''
			}
			, is_on_detail: false
			, item_detail: {

			}
			, modal_option: {
				top: true
				,title: '영업점 조회'
				,bottom: true
			}

			, item_search_agency: {
				page: 1
				, list_cnt: 10
				, tCnt: 0
				, search_type: 'shop_name'
				, search_value: ''
				, agency_type: 'A001003'
			}
			, items_agency: []
			, item_agency_select: ''
			, is_bussiness: false
			, item_agency: ''
			, items_virtual: []
		}
	}
	, computed: {
		list_history: function(){
			return this.items.filter( ( item ) => {
				switch (item.history_type){
					case '0':
						item.history_type_name = '출금'
						item.history_type_color = 'red'
						item.amount *= -1
						break;
					case '1':
						item.history_type_name = '입금'
						item.history_type_color = 'blue'
						break;
				}
				this.codes["W001"].items.filter( (status) => {
					if(item.send_type == status.code_index){

						item.send_type_name = status.code_name
						item.send_type_color = status.code_color
					}
				})

				this.codes["U001"].items.filter( (status) => {
					if(item.status == status.code_index){
						item.status_name = status.code_name
						item.status_color = status.code_color
					}
				})


				if(item.bank_depositor != item.bank_holder){
					if(item.status == '1'){
						if(item.sDate?.substring(0, 10) == this.$date.getToday('-')){
							item.is_cancel = true
						}
					}else{
						item.is_confirm = true
					}
				}
				let now = this.$date.getToday('-')
				if(this.user.account_type != "A001003") {
					if (item.wDate?.substring(0, 10) == now) {
						if(item.account_id == this.user.account_id){
							item.is_delete = true
						}else{
							if(item.status == 0){
								item.is_cancel = true
							}
						}
					}
					if (this.user.account_type != "A001003" && item.status == 0) {
						item.is_deposit = true
					}
				}
				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getWalletDeposit'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(page){
				this.item_search.page = page
			}

			this.getData()
		}
		, postDeposit: async function(){

			try{
				if(!confirm("시스템에 기록되지 않는 외부 입금건에 대한 수동 처리입니다. 입금처리 하시겠습니까?")){
					return false
				}
				if(!this.item.virtual_uid){
					throw "지갑을 선택하세요"
				}
				if(!this.item.shop_uid){
					throw "영업점을 선택하세요"
				}
				if(!this.item.amount){
					throw "금액을 입력하세요"
				}
				if(!this.item.memo){
					throw "내용을 입력하세요"
				}
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAdminDeposit'
					,data: this.item
				})

				if(result.success){
					this.item.amount = ''
					this.item.memo = ''
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		, confirmDeposit:async function(item){
			try{
				if(!confirm("승인 하시겠습니까?")){
					return false
				}

				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/confirmAdminDeoposit'
					,data: {
						uid: item.uid
					}
				})

				if(result.success){
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, cancelDeposit: async function(item){
			try{
				if(!confirm("승인 취소 하시겠습니까?")){
					return false
				}

				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/cancelAdminDeoposit'
					,data: {
						uid: item.uid
					}
				})

				if(result.success){
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, deleteDeposit: async function(item){
			try{
				if(!confirm("삭제 하시겠습니까?")){
					return false
				}

				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/deleteAdminDeoposit'
					,data: {
						uid: item.uid
					}
				})

				if(result.success){
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onDetail: function(item){
			this.item_detail = item
			this.is_on_detail = true
		}

		, getAgency: async function(page = 1){
			this.$bus.$emit('on', true)
			try{
				this.item_search_agency.page = page
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getAgencyList'
					, data: this.item_search_agency
				})

				if(result.success){
					this.items_agency = result.data.result
					this.$set(this.item_search_agency, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, selectAgency: function(agency){
			this.item_agency_select = agency
		}
		, setAgency: function(){
			this.item_agency = this.item_agency_select
			this.item.shop_uid = this.item_agency.shop_uid
			this.is_bussiness = false
		}
		, onAgency: function(){
			this.is_bussiness = true
		}
		, getVirtualList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getVirtualList'
					, data: {
					}
				})
				if (result.success) {
					this.items_virtual = result.data
					this.item_search.virtual_uid = this.items_virtual[0].uid
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getVirtualList()
		await this.getData()
	}
}
</script>

<style>
.tab h6 { border-right: 1px solid white; cursor: pointer}
</style>